import concat from 'lodash/concat';
import each from 'lodash/each';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import head from 'lodash/head';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/orderBy';
import tail from 'lodash/tail';

import getSourceData from './getSourceData';

export const getDataPerSource = ({ data, sources, table }) =>
  reduce(
    data,
    (acc, item, index) => {
      return {
        ...acc,
        [sources[index]]: getSourceData({
          source: sources[index],
          data: item,
          table,
        }),
      };
    },
    {},
  );

// Merge data into initial only if it exists, otherwise ignore
export const mergeSourcesData = ({ dataPerSource, sources }) => {
  let initial = dataPerSource[head(sources)];

  each(tail(sources), source => {
    const entries = dataPerSource[source];

    each(entries, entry => {
      const match = findIndex(initial, { name: entry.name });

      if (match !== -1) {
        const obj = { [source]: entry[source] };
        initial[match] = { ...initial[match], ...obj };
      }
    });
  });

  return initial;
};

export const sortMultiSourceData = ({ data }) => {
  const sortedResults = reverse(
    sortBy(data, (valuesToSortBy) =>
      reduce(omit(valuesToSortBy, ['name', 'text', 'hint']), (result, value) => result + value, 0),
    ),
  );

  return sortedResults;
};

export const rearrangeExcludedItems = ({ data, sorting }) => {
  const exclude = get(sorting, 'exclude', []);

  const sortedData = filter(data, (item) => indexOf(exclude, item.name) === -1);
  const excludedData = filter(data, (item) => indexOf(exclude, item.name) !== -1);

  return concat(sortedData, excludedData);
};

export const omitZeroValues = ({ data }) =>
  filter(data, (values) =>
    reduce(omit(values, ['name', 'text', 'hint']), (sum, value) => sum + value, 0),
  );

/**
 * The multi source data transformer is used from distributions per source
 * The charts using it show unmerged data for more than one source
 */
const getMultiSourceData = ({ sources, data, table, sorting, omitZeros }) => {
  if (isEmpty(sources) || isEmpty(data)) return [];

  const dataPerSource = getDataPerSource({ data, sources, table });
  const mergedData = mergeSourcesData({ dataPerSource, sources });

  let sortedData;

  if (isEmpty(sorting)) {
    sortedData = mergedData;
  } else {
    const sortedResults = sortMultiSourceData({ data: mergedData });
    const rearrangedData = rearrangeExcludedItems({ data: sortedResults, sorting });
    sortedData = rearrangedData;
  }

  if (!omitZeros) return sortedData;

  const nonZeroData = omitZeroValues({ data: sortedData });

  return nonZeroData;
};

export default getMultiSourceData;
